import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "results", "searchInput", "searchButton" ]
  static values = { searchFormUrl: String }

  connect() {
    $(this.element).on("show.bs.modal", this.show.bind(this));
    Turbo.cache.exemptPageFromCache();
  }

  show(event) {
    let workListId = event.relatedTarget.dataset.workListId;
    let newFormTargetAction = this.searchFormUrlValue.replace("work-list-id", workListId);

    if (!this.formTarget.action.includes(newFormTargetAction)) {
      this.resultsTarget.innerHTML = "";
      this.searchInputTarget.value = "";
      this.formTarget.action = newFormTargetAction;
      this.searchButtonTarget.click();
    }
  }

  addedCustomer(event) {
    event.submitter.classList.add("btn-outline-success");
    event.submitter.innerHTML = "<i class='fas fa-check'></i> Added"
  }
}
