import { Controller } from "stimulus"
import { singleFileUpload, multipleFileUpload } from "../components/uppy";

export default class extends Controller {
  connect() {
    // attach to input type=file
    //if (this.element.multiple) {
    multipleFileUpload(this.element);
    //} else {
    //  singleFileUpload(this.element)
    //}
  }
}
