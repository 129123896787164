import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "periodicitySelect",
    "dailyInterval",
    "weeklyInterval",
    "monthlyInterval",
  ]

  connect() {
    this.hideAllIntervalTargets();

    this.showIntervalTarget(this.periodicitySelectTarget.value);
  }

  periodicitySelected(event) {
    this.hideAllIntervalTargets();

    this.showIntervalTarget(event.currentTarget.value);

    // todo: reset the other inputs
    // check server side validation works
  }

  hideAllIntervalTargets() {
    this.dailyIntervalTarget.classList.add("d-none");
    this.weeklyIntervalTarget.classList.add("d-none");
    this.monthlyIntervalTarget.classList.add("d-none");
  }

  showIntervalTarget(name) {
    if (name.length > 0) {
      this[name + "IntervalTarget"].classList.remove("d-none");
    }
  }
}
