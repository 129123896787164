import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkBox" ]

  add(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.checkBoxTarget.checked = true;

    event.currentTarget.form.submit();
  }
}
