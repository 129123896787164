import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    workListId: String,
    entryId: String,
    price: String,
  }

  mousedown(event) {
    if (event.target.closest(".btn") == null) {
      this.mouseDownTime = new Date().getTime();
    }
  }

  edit(_event) {
    const clickTime = new Date().getTime();
    const holdTime = clickTime - this.mouseDownTime;

    if (holdTime < 250) {
      this.showModal();
    }
  }

  priceValueChanged() {
    const event = new CustomEvent("entryPriceChanged", { bubbles: true });

    this.element.dispatchEvent(event);
  }

  showModal(_event) {
    document.getElementById("editEntry")["editEntryController"].show(
      {
        workListId: this.workListIdValue,
        entryId: this.entryIdValue,
      }
    )
  }
}
