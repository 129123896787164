import NestedForm from "stimulus-rails-nested-form";
import dayjs from "dayjs";
import postData from "../utilities/post_data";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
export default class extends NestedForm {
  static values = { serviceOccurencesPath: String }

  static targets = [
    "addServiceDropdown",
    "price",
    "workListLabel",
    "date",
    "serviceName",
    "serviceDescription",
    "servicePrice",
  ];
  static dateFormat = "DD/MM/YYYY";

  connect() {
    super.connect();

    this.element[this.identifier] = this;

    this.initSelectize();
  }

  add(e) {
    super.add(e);

    this.initSelectize();
    this.updateNextOccurences();
    this.sumPrices();
  }

  remove(e) {
    super.remove(e);

    this.sumPrices();
  }

  dateChanged(e) {
    this.updateNextOccurences();
  }

  initSelectize() {
    this.$select = $(this.addServiceDropdownTargets).filter(":visible").selectize({
      placeholder: 'Select a service...',
      render: {
        option: function(data, escape) {
            return '<div class="option service-option">' +
                (data.name ? '<span class="name">' + escape(data.name) + '</span>' : '') +
                (data.description ? '<span class="description">' + escape(data.description) + '</span>' : '') +
                (data.recurrence_to_s ? '<span class="recurrence_to_s">' + escape(data.recurrence_to_s) + '</span>' : '') +
                (data.price_to_s ? '<span class="price_to_s">£' + escape(data.price_to_s) + '</span>' : '') +
            '</div>';
        },
      },
      onInitialize: this.updateNextOccurences.bind(this),
      onItemAdd: this.addFromSelectizeItem.bind(this)
    });
  }

  addFromSelectizeItem(value, $item) {
    this.populateInputs(value, $item);
    this.updateNextOccurences();
    this.sumPrices();
  }

  populateInputs(value, $item) {
    const selectize = this.$select[0].selectize;
    const $container = $item.parents(".nested-form-wrapper");
    const data = selectize.options[value];

    const $nameInput = $container.find("[data-job-service-nested-form-target='serviceName']");
    const $descriptionInput = $container.find("[data-job-service-nested-form-target='serviceDescription']");
    const $priceInput = $container.find("[data-job-service-nested-form-target='servicePrice']");

    $nameInput.val(data.name)
    $descriptionInput.val(data.description)
    $priceInput.val(data.price_to_s)
  }

  jobDate() {
    if (this.dateTarget.value.length > 20) {
      return dayjs(this.dateTarget.value.substring(0, 10), 'YYYY-MM-DD');
    } else if (this.dateTarget.value.includes("-")) {
      return dayjs(this.dateTarget.value);
    } else {
      return dayjs(this.dateTarget.value, this.constructor.dateFormat, false);
    }
  }

  updateNextOccurences() {
    const labels = this.element.querySelectorAll(".job_jobs_services_add_to_work_list label");
    const checkboxes = this.element.querySelectorAll(".job_jobs_services_add_to_work_list");

    const selectedServiceIds = this.addServiceDropdownTargets.map((dropdown, index) => dropdown.selectize?.items[0]);

    postData(this.serviceOccurencesPathValue, {
      service_occurence: {
        start_date: this.jobDate().format(),
        service_ids: selectedServiceIds,
      }
    }).then(response => {
      this.addServiceDropdownTargets.forEach((dropdown, index) => {
        const selectize = dropdown.selectize;

        if(selectize != undefined && selectize.items[0] != undefined) {
          const service = response.filter(obj => {
            return obj.service_id === selectize.items[0]
          })[0];
          const nextOccurrence = service["next_occurs_on"];

          if (nextOccurrence == undefined) {
            checkboxes[index].setAttribute("hidden", true);
          } else {
            labels[index].innerHTML = "Add customer to plan on " + dayjs(nextOccurrence).format(this.constructor.dateFormat) + "?";
            checkboxes[index].removeAttribute("hidden");
          }
        }
      })
    });
  }

  sumPrices() {
    var prices = this.servicePriceTargets.map(input => {
      const floatVal = parseFloat(input.value || 0);

      if (isNaN(floatVal)) {
        return 0;
      } else if (input.offsetParent === null) { // hidden element
        return 0;
      } else {
        return floatVal;
      }

    })

    var total = prices.reduce((a, b) => a + b, 0);

    this.priceTarget.innerHTML = "£" + total.toFixed(2);
  }

  changedPrice() {
    this.sumPrices();
  }
}
