import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  redirect(e) {
    e.preventDefault();

    Rails.ajax({
      type: "post",
      url: this.data.get("url"),
      success: function(data) {
        stripe
          .redirectToCheckout({
            sessionId: data.session_id
          })
      }
    })
  }
}
