import Sortable from "stimulus-sortable";
import Rails from "@rails/ujs";
import currency from "currency.js";

export default class extends Sortable {
  static values = {
    workListId: String,
  }

  connect() {
    super.connect();

    this.element.addEventListener("entryPriceChanged", this.updateTotalPrice.bind(this));
  }

  end(event) {
    let data = new FormData();
    data.append("entry[position]", event.newIndex + 1);
    data.append("entry[work_list_id]", event.to.dataset.workListEntriesWorkListIdValue);

    Rails.ajax({
      url: event.item.dataset.sortableUpdateUrl,
      type: "PATCH",
      data: data,
    }); // auto rendering the response doesnt work :(
  }

  change() {
    this.updateTotalPrice();
  }

  updateTotalPrice() {
    let prices = Array.from(this.element.children).map(element => currency(element.dataset.entryPriceValue, { symbol: '£' }));
    let totalPrice = currency(0, { symbol: '£' });

    if (prices.length > 0) {
      totalPrice = prices.reduce((accumulator, price) => accumulator.add(price));
    }

    document.getElementById(`work_list_${this.workListIdValue}_total_price`).innerHTML = totalPrice.format();
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions () {
    return {
      group: "work-day",
      draggable: ".entry",
      //forceFallback: isMobile(),
      animation: 150,
      delayOnTouchOnly: true,
      delay: 50,
      onEnd: this.end.bind(this),
      onSort: this.change.bind(this),
      onAdd: this.change.bind(this)
    }
  }

  get isMobile () {
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
}
