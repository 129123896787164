import { Controller } from "stimulus"

var timeout = null;

export default class extends Controller {

  connect() {
  }

  inputChange(event) {
    this.submitForm(event.currentTarget.form);
  }

  submitForm(form) {
    form.submit();
  }

}
