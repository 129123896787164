import Lightbox from "stimulus-lightbox";
//
export default class extends Lightbox {
  get defaultOptions () {
    return {
      selector: 'a',
      thumbnail: true,
    }
  }
}
