import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  static targets = [ "addServiceDropdown" ];

  connect() {
    super.connect();

    this.$select = $(this.addServiceDropdownTarget).filter(":visible").selectize({
      placeholder: 'Select a service...',
      render: {
        option: function(data, escape) {
            return '<div class="option service-option">' +
                (data.name ? '<span class="name">' + escape(data.name) + '</span>' : '') +
                (data.description ? '<span class="description">' + escape(data.description) + '</span>' : '') +
                (data.recurrence_to_s ? '<span class="recurrence_to_s">' + escape(data.recurrence_to_s) + '</span>' : '') +
            '</div>';
        },
        item: function(data, escape) {
          var div = document.createElement("DIV");
          div.classList.add("item");
          div.innerHTML = escape(data.name);
          for (const [key, value] of Object.entries(data)) {
            div.setAttribute('data-' + key.replace(/_/g, "-").replace("$", ""), value);
          }
          div.serviceData = data;
          return div;
        }
      },
      onItemAdd: this.addFromSelectizeItem.bind(this)
    });
  }

  addFromSelectizeItem(value, $item) {
    const data = $item[0].dataset;

    this.addNewService(data);
  }

  add() {
    this.addNewService({
      name: '',
      description: '',
      priceToS: ''
    });
  }

  addNewService(data) {
    const content = this.templateTarget.innerHTML.
      replace(/NEW_RECORD/g, new Date().getTime()).
      replace(/NAME_VALUE/g, data.name || '').
      replace(/DESCRIPTION_VALUE/g, data.description || '').
      replace(/PRICE_VALUE/g, data.priceToS || '').
      replace('value="' + (data.recurrencePeriodicity || '') + '"', 'value="' + (data.recurrencePeriodicity || '') + '" selected="true"').
      replace(/RECURRENCE_DAILY_INTERVAL_VALUE/g, data.recurrenceDailyInterval || '').
      replace(/RECURRENCE_WEEKLY_INTERVAL_VALUE/g, data.recurrenceWeeklyInterval || '').
      replace(/RECURRENCE_MONTHLY_INTERVAL_VALUE/g, data.recurrenceMonthlyInterval || '')

    this.targetTarget.insertAdjacentHTML('beforebegin', content)

    this.$select[0].selectize.clear();
  }
}
