import { Controller } from "stimulus"
import { useMutation } from "stimulus-use"

export default class extends Controller {
  connect() {
    useMutation(this, { childList: true })
  }

  mutate() {
    this.element.scrollTop = this.element.scrollHeight
  }
}
