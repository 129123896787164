import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  click() {
    var turboFrame = document.getElementById("customer_messages");
    if (turboFrame.offsetParent === null) { // if turboFrame is hidden (e.g. mobile view)
      Turbo.visit(this.urlValue)
    } else {
      turboFrame.src = this.urlValue;
    }
  }
}
