import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "workDay", "form" ]
  static values = { formUrl: String }

  connect() {
    $(this.element).on("show.bs.modal", this.show.bind(this));
  }

  show(event) {
    const name = event.relatedTarget.data.name;
    const day = event.relatedTarget.data.day;
    const workListId = event.relatedTarget.data.workListId;

    const fp = flatpickr(this.workDayTarget, { dateFormat: "d/m/Y" });
    fp.setDate(day, false, "d/m/Y");
    this.nameTarget.value = name;
    this.formTarget.action = this.formUrlValue.replace("work_list_id", workListId);
  }

  complete() {
    $(this.element).modal("hide");
  }
}
