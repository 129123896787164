// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Sortable from "stimulus-sortable"
import NestedForm from "stimulus-rails-nested-form"
import Flatpickr from "stimulus-flatpickr"

const application = Application.start()
const context = require.context("./", true, /\.js$/)
application.load(definitionsFromContext(context))

// Stimulus Components - https://stimulus-components.netlify.app
application.register("sortable", Sortable)
application.register("nested-form", NestedForm)

// import Flatpickr
require("flatpickr/dist/flatpickr.css")
application.register("flatpickr", Flatpickr)
