import { Controller } from "stimulus";
import autocomplete from "autocomplete.js";
import Mustache from "mustache";

export default class extends Controller {
  static targets = ["field", "template"];
  static values = { options: Array }

  source() {
    const url = this.data.get("url");
    return (query, callback) => {
      callback(
        Array.from(new Set(this.optionsValue)).filter(suggestion => {
          return suggestion.toLowerCase().includes(query.toLowerCase());
        })
      );
    };
  }

  connect() {
    const template = this.templateTarget.innerHTML;

    this.ac = autocomplete(this.fieldTarget, { hint: false }, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          suggestion: (suggestion) => {
            return Mustache.render(template, { suggestion: suggestion });
          },
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      this.ac.autocomplete.setVal(suggestion);
    });
  }
}
