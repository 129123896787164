import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "entry" ];
  static values = {
    id: String,
    name: String,
    day: String,
  }

  copy(event) {
    $('#copyWorkList').modal('show', { data: {
      day: this.dayValue,
      workListId: this.idValue,
    }});
  }

  edit(event) {
    $('#editWorkList').modal('show', { data: {
      workListId: this.idValue,
      name: this.nameValue,
      day: this.dayValue,
    }});
  }
}
