import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "form",
  ]

  static values = {
    editPath: String
  }

  connect() {
    this.element["editEntryController"] = this;
  }

  show(data) {
    this.formTarget.src = this.editPathValue.
      replace(/work_list_id/g, encodeURIComponent(data.workListId)).
      replace(/entry_id/g, encodeURIComponent(data.entryId))

    $(this.element).modal("show");
  }

  removeComplete(event) {
    if (event.detail[0]) {
      this.complete();
    }
  }

  complete() {
    $(this.element).modal("hide");
  }
}
