/* eslint no-console:0 */

// Sentry
import 'utilities/sentry';

// SCSS
import styles from '../app/styles/app';

// Bootstrap
import 'bootstrap';

// Stimulus
import 'controllers';

// Rails UJS
import Rails from 'rails-ujs';
Rails.start();

// ActionCable
import 'channels';

// Turbo
import { Turbo, cable } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

// Selectize
window.$ = window.jQuery = require('jquery');
require('selectize');

// Initial hard page load
document.addEventListener('turbo:load', function() {
  $('.selectize').selectize();

}, { once: true });

// Subsequent turbo renders
document.addEventListener('turbo:render', function() {
  $('.selectize').selectize();
});
